<template>
     <div>
        <!-- 问题咨询排名 / 设置统计分类-->
        <popup @closeEvent="closeEvent" v-if="statisticalClassifiVisible" class="statistical-classifi-visible">
            <div slot="popup-name">设置统计分类</div>
            <div slot="popup-con">
                <div class="popup-container-self">
                    <div class="list-box">
                        <div class="title-top">
                            <div class="left-title-top">分类</div>
                            <div class="right-title-top">关联知识库/分类/意图</div>
                        </div>
                        <div class="scroll-box">
                            <div class="item-statistical-classifi" v-for="(item,index) in setStatisticalClassifi.questionTypeConfigs" :key="index">
                                <el-input v-model.trim="item.name" placeholder="请输入分类名字"></el-input>
                                <el-cascader
                                    collapse-tags
                                    :ref="'changekeyWordRecommend'+index"
                                    :options="intentTree"
                                    :props=" {multiple: true, value: 'id', label: 'name',emitPath:false}"
                                    :show-all-levels="false"
                                    placeholder="搜索或选择意图"
                                    v-model="item.intentIds"
                                    @change="changeIntentGuan($event,item,index)"
                                    filterable>
                                </el-cascader>
                                <i
                                    v-show="setStatisticalClassifi.questionTypeConfigs.length > 1"
                                    class="el-icon-delete cursor"
                                    @click="confirmDeleteKeyWord(index)">
                                </i>        
                            </div>
                        </div>
                        <div class="add-classifi-btn">
                            <div class="add-btn guoran-tongyichicun-16-13-xinjian iconfont"  @click="setStatisticalClassifi.questionTypeConfigs.push({name:'',intentIds:[]})"></div>
                        </div>
                    </div>
                    <div class="statistical-rank">
                        <div class="statistical-rank-left">系统排名前</div>
                        <div class="statistical-rank-right">
                            <el-input v-model.trim="setStatisticalClassifi.topN" min="1" max="100" placeholder="请输入" type="number" @change="changeFn"></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">取 消</el-button>
                <el-button class="confirm-btn" type="primary" @click="submitForm('')" round style="padding:0 !important;">确定</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import popup from '../../../components/popup.vue'
export default {
    components:{
        popup
    },
    data(){
        return{
            intentTree:[],
            setStatisticalClassifi:{
                questionTypeConfigs:[{
                    name:'',
                    intentIds:[],
                    ext: {},
                }],
                topN:10,
            },
            allIntent:[]
        }
    },
    props:{
        activeScopeValue:{
            type:String,
            default:''
        },
        activeScopeType:{
            type:String,
            default:''
        },
        statisticalClassifiVisible:{
            type:Boolean,
            default:false
        },
    },
    watch:{
        statisticalClassifiVisible(n){
            if(n){
                this.allIntent = [];
                this.getIntentList();
                this.getData();
            }
        }
    },
    methods:{
        changeFn(value){
            this.setStatisticalClassifi.topN = this.setStatisticalClassifi.topN.replace(/[^0-9]/g,'');
            if(value < 1){
                this.setStatisticalClassifi.topN = 1;
            }
            if(value > 100){
                this.setStatisticalClassifi.topN = 100;
            }
        },
        getData(){
            this.FetchGet(
                this.requestUrl.statistics.questionType + this.formatterActiveScopeValue(this.activeScopeValue)).then((res) => {
                if(res.code === '0'){
                    this.setStatisticalClassifi.questionTypeConfigs = res.data.questionTypeConfigs;
                    this.setStatisticalClassifi.topN = res.data.topN || 10;
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        getIntentList() {
            let scope = this.formatterActiveScopeValue(this.activeScopeValue)
            this.FetchGet(
                this.requestUrl.statistics.getIntentTreeByScope + scope,
                { scopeType: this.activeScopeType }
            ).then((res) => {
                if (res.code === "0") {
                    let nodeTree = JSON.parse(JSON.stringify(res.data));
                    this.intentTree = nodeTree;
                    this.handlerIntent(nodeTree);
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
        },
        handlerIntent(list){
            list.forEach((item,index) => {
                if(item.type === 'INTENT'){
                    this.allIntent.push(item.id)
                } else {
                    this.handlerIntent(item.children || [])
                }
            })

        },
        formatterActiveScopeValue(activeScopeValue) {
            return activeScopeValue.split("__")[0];
        },
        confirmDeleteKeyWord(index) {
            this.setStatisticalClassifi.questionTypeConfigs.splice(index, 1);
        },
        changeIntentGuan(datas,item,index){
            item.intentIds = [...item.intentIds];
        },
        closeEvent(){
            this.$emit('update:statisticalClassifiVisible',false)
            this.setStatisticalClassifi = {
                questionTypeConfigs:[{
                    name:'',
                    intentIds:[],
                    ext:{}
                }],
                topN:10
            }
        },
        submitForm(){
            let flag = true;
            console.log( this.setStatisticalClassifi.questionTypeConfigs);
            if(!this.setStatisticalClassifi.questionTypeConfigs || this.setStatisticalClassifi.questionTypeConfigs.length === 0){
                flag = false;
                this.$message.closeAll()
                this.$message.error("至少设置一个分类！");
            } else {
                this.setStatisticalClassifi.questionTypeConfigs.forEach((item,index) => {
                    if(item.name === ''){
                        flag = false;
                        this.$message.closeAll()
                        this.$message.error("分类名字不能为空！");
                    }
                    if(flag) {
                        if(item.intentIds.length === 0 || !item.intentIds){
                            flag = false;
                            this.$message.closeAll()
                            this.$message.error("关联意图不能为空");
                        }
                        let _flags = false;
                        this.allIntent.forEach((v,i) => {
                            item.intentIds.forEach((a,b) => {
                                if(v == a){
                                    console.log();
                                    _flags = true
                                }
                            })
                            
                        })
                        if(!_flags){
                            flag = false;
                            this.$message.closeAll()
                            this.$message.error("关联意图不能为空");
                        }
                    }
                })
            }
            if (!(/(^[1-9]\d*$)/.test(this.setStatisticalClassifi.topN))) { 
                flag = false;
                this.$message.closeAll()
                this.$message.error("请输入正正数");
            }
            let arr = [...new Set(this.setStatisticalClassifi.questionTypeConfigs.map(item => item.name))];
            if(arr.length != this.setStatisticalClassifi.questionTypeConfigs.length){
                flag = false;
                this.$message.closeAll()
                this.$message.error("分类名称重复，请修改后保存！");
            }
            if (!flag) {
                return false;
            } else {
                let obj = JSON.parse(JSON.stringify(this.setStatisticalClassifi))
                this.FetchPost(
                    this.requestUrl.statistics.questionConsult +
                        this.formatterActiveScopeValue(this.activeScopeValue) +
                        "?scopeType=" +
                        this.activeScopeType,
                   obj
                ).then((res) => {
                    if(res.code === '0'){
                        this.$message.closeAll()
                        this.$message.success("统计分类设置成功");
                        this.$emit('updateSetStatisticalClassifi');
                        this.closeEvent();
                    } else {
                        this.$message.error(res.message);
                    }
                })
            }
        },
    }
}
</script>

<style lang="less" scoped>
    .statistical-classifi-visible{
        /deep/ .el-input__inner{
            height: 38px !important;
        }
        /deep/ #popup-assembly{
            width: 796px;
        }
        .popup-container-self{
            max-height: 728px;
            background: #FBFCFD;
            border-radius: 5px;
            width: 100%;
            color: #000000;
            font-size: 14px;
        }
        .list-box{
            margin-bottom: 20px;
            width: 100%;
            .title-top{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                height: 40px;
                background: #F6F8FD;
                border-radius: 5px 5px 0px 0px;
                padding: 0 13px;
               
                .left-title-top{
                    width: 220px;
                    font-size: 14px;
                    text-align: left;
                }
                .right-title-top{
                    width: 65%;
                    font-size: 14px;
                    text-align: left;
                    margin-left: 10px;
                }
            }
            .scroll-box{
                max-height: 350px;
                overflow-x: hidden;
                overflow-y: auto;
            }
            .item-statistical-classifi{
                padding: 0 13px;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                .el-input{
                    width: 220px;
                    margin-right: 14px;
                }
                /deep/ .el-cascader{
                    width: 423px;
                    margin: 0 10px;
                    .el-cascader__tags{
                        .el-tag{
                            max-width: 80%;
                            height: 24px;
                            background: #EDF0F8;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;
                            align-items: center;
                            font-size: 13px;
                            color: #333333;
                            &:first-child{
                                background: #366AFF;
                                color: #FFFFFF;
                                .el-tag__close{
                                    font-size: 14px;
                                    background-color: transparent;
                                    &:hover{
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                        .el-cascader__search-input{
                            display: none;
                        }
                    }
                }
                .el-icon-delete{
                    color: #366AFF;
                    font-size: 18px;
                    margin-left: 9px;
                }
            }
            .add-classifi-btn{
                display: flex;
                justify-content: center;
                .add-btn{
                    width: 31px;
                    height: 31px;
                    background: #FFFFFF;
                    border: 1px solid #D5E0FF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    cursor: pointer;
                    color: #366AFF;
                }
            }
        }
        .statistical-rank{
            display: flex;
            align-items: center;
            padding-bottom: 14px;
            padding: 14px;
            .statistical-rank-left{
                width: 70px;
                margin-right: 15px;
                white-space: nowrap;
            }
            .statistical-rank-right{
                width: calc(100% - 95px);
                /deep/ .el-input{
                    .el-input__inner{
                        line-height: 1px!important;
                    }
                }
            }
        }
    }
</style>