<template>
  <div class="staff-customer">
    <div class="staff-customer-input">
      <el-input
          v-model="keyword"
          placeholder="搜索"
          size="small"
          @keyup.enter.native="searchEmployee"
          @input="monitorSearchNull"
      ></el-input>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="选择员工" name="staff">
        <el-select
            v-model="corpId"
            placeholder="请选择"
            v-if="corpList.length > 1"
            size="small"
            style="width: 100%;margin-bottom: 10px"
        >
          <el-option
              v-for="item in corpList"
              :key="item.originData.corpId"
              :label="item.label"
              :value="item.originData.corpId">
          </el-option>
        </el-select>
        <div v-for="(item,index) in treeData" :key="index">
          <el-tree
              key="selectOrganizationTreeLazy"
              :data="[item]"
              id="selectOrganizationTreeLazy"
              class="filter-tree"
              node-key="id"
              :default-expanded-keys="defaultExpandedKeysMember"
              :expand-on-click-node="false"
              :ref="item.id+'TreeLazy'">
            <span
                class="custom-tree-node"
                slot-scope="{node,data}" @click="onNodeClick(data,'',item,node)">
              <span class="custom-tree-node-left">
                <!-- // 员工树结构的数据 type=0是员工，type=1是部门 -->
                <i
                    :class="['el-icon-caret-right',data.showChildren?'down':'right']"
                    v-show="data.type == 1"
                    @click.stop="nodeExpand(data,node)">
                </i>
                <span
                    class="data-label"
                    v-if="
                    data.isWeWorkThirdData ||
                    item.isWeWorkThirdData ||
                    (item.originData&&
                    item.originData.wechatBotDTO&&
                    item.originData.wechatBotDTO.bindType===0&&
                    item.originData.wechatBotDTO.wechatType===0)">
                  <open-data :type="data.type == 1 ? 'departmentName' : 'userName'"
                             :openid="data.label"></open-data>
                </span>
                <span v-else class="data-label">{{ data.label }}</span>
                <template v-if="data.originData && data.originData.departments && keyword">
                  <span>&#8195</span>
                  <div v-for="(cell, index) in data.originData.departments"
                       :key="index"
                  >
                    <el-tooltip placement="top" effect="light">
                      <div slot="content">
                        (
                        <template
                            v-if="data.isWeWorkThirdData"
                        >
                          <open-data :type="'departmentName'"  :openid="cell.fullName"></open-data>
                        </template>
                        <span
                            v-else
                            v-html="cell.fullName"
                        ></span>
                        )
                      </div>
                      <div
                          v-if="data.isWeWorkThirdData"
                      >
                        ( <open-data :type="'departmentName'"  :openid="cell.name"></open-data> )
                      </div>
                      <span v-else>({{ cell.name }})</span>
                    </el-tooltip>
                  </div>
                </template>
              </span>
              <span v-show="data.checked">
                <i :class="['el-icon-check']"></i>
              </span>
            </span>
          </el-tree>
        </div>
      </el-tab-pane>
      <el-tab-pane label="选择上下游" name="upDown">
        <el-tree
            class="updown-tree"
            :props="{
          isLeaf: 'leaf',
          label: 'name',
          children:'children'
        }"
            :data="updownDateTree"
            :load="loadNode"
            lazy
            ref="upDownTree"
            :expand-on-click-node="false"
            node-key="id">
          <span :class="['custom-tree-node',!data.wxOpenId&&data.selfType===3 ? 'not-allowed' : 'allowed']"
                slot-scope="{node,data}" @click="onNodeClick(data,'updown',data,node)">
            <span class="custom-tree-node-left">
              <span class="data-label">{{ data.name }}</span>
            </span>
            <span v-if="data.checked">
              <i :class="['el-icon-check']"></i>
            </span>
          </span>
        </el-tree>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OpenData from "./openData";

export default {
  name: "staffCustomer",
  components: { OpenData },
  data() {
    return {
      activeName: "staff",
      defaultProps: {
        label: "label",
        children: "children",
        isLeaf: "leaf"
      },
      clientId: "",
      keyword: "",
      defaultExpand: false,
      checkMemberList: [],
      isLazy: false,
      defaultExpandedKeys: [],
      defaultExpandedKeysMember: [],
      treeData: [],
      updownDateTree: [],
      corpId: "",
      currentPage: 1,
      pageSize: 20,
      corpList: [],
      staffHasNext: false,
      updownNode: "",
      updownResolve: ""
    };
  },
  props: ["selectClient", "clientType", "clientValue", "memberInfo4Client", "isMultiple","checkedList","isChooseDeptMember"],
  mounted() {
    this.getData(null);
  },
  methods: {
    // 递归获取所有节点
    handlerUpDown(moduleDataList, data) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
              this.handlerUpDown(moduleDataList[i].children, data);
            }
          }
        }
        resolve(true);
      });
    },
    //懒加载上下游员工
    loadNode(node, resolve) {
      console.log("懒加载");
      let uid = localStorage.getItem("_uid");
      if (node.level === 0) {
        let datas = [];
        this.updownNode = node;
        this.updownResolve = resolve;
        let url = "/portal-api/upAndDown/selectOrganizationById" + "?userId=" + uid;
        // this.$http.get(url).then(res => {
        this.FetchGet(url).then(res => {
          datas = res.data || [];
          datas.forEach(element => {
            element.leaf = false;
            element.checked = false;
            if (element.organizationId) {
              element.selfType = 3;
            } else {
              element.selfType = 2;
            }

          });
          this.updownDateTree = datas;
          this.getAllNodeId(datas, "show");
          return resolve(datas);

        });
        /* return resolve([{ name: 'region' }]);*/
      }
      if (node.level >= 1) {
        let datas = [];
        let userDatas = [];
        let url = "/portal-api/upAndDown/selectOrganizationById" + "?id=" + node.data.id + "&userId=" + uid;
        this.FetchGet(url).then(res => {
          datas = res.data || [];
          datas.forEach(element => {
            element.leaf = false;
            element.checked = false;
            if (element.organizationId) {
              element.selfType = 3;
            } else {
              element.selfType = 2;
            }
          });
          let departUrl = "/portal-api/upAndDown/selectUpAndDownMemberByOrganizationId?organizationId=" + node.data.id + "&userId=" + uid;
          this.FetchGet(departUrl).then(res => {
            userDatas = res.data;
            userDatas.forEach(element => {
              element.leaf = true;
              element.checked = false;
              if (element.organizationId) {
                element.selfType = 3;
              } else {
                element.selfType = 2;
              }
            });
            datas = datas.concat(userDatas);
            this.getAllNodeId(datas, "show");
            node.data.children = datas;
            return resolve(datas);
          });
        });
      }
    },
    // 节点被展开
    nodeExpand(data, node) {
      data.showChildren = !data.showChildren;
      if (data.showChildren) {

        this.getData(data.id, data, node);
      } else {
        data.children = [];
      }
    },
    // 获取员工数据
    getData(departId, data, node) {
      let axiosDatas = [];
      let userId = localStorage.getItem("_uid");
      let url = departId ? "/portal-api/department/corpListLazy" + "?departId=" + departId + "&userId=" + userId : "/portal-api/department/corpListLazy" + "?userId=" + userId;
      this.FetchGet(url).then(res => {
        let ids = [];
        axiosDatas = this.handlerCheckedSelf(res || [], [], departId ? departId : -1);
        this.getAllNodeId(axiosDatas, "show");
        if (departId) {
          data.children = [...data.children, ...axiosDatas];
          this.defaultExpandedKeysMember = [...this.defaultExpandedKeysMember, ...[departId]];
          data.showChildren = true;
        } else {
          this.treeData = [...axiosDatas];
          this.defaultExpandedKeysMember = [];
          this.corpList = JSON.parse(JSON.stringify(axiosDatas));
        }
        this.treeData.forEach(item => {
          if (!departId) {
            item.showChildren = false;
            item.children = [];
          }
          this.$nextTick(() => {
            let ref = item.id + "TreeLazy";
            setTimeout(() => {
              this.getNodeChecked(ref, item, ids);

            }, 500);
          });
        });
        if (this.treeData.length > 0){
          this.corpId = this.treeData[0].originData?this.treeData[0].originData.corpId:''
        }
        console.debug('treeData',this.treeData)
      });
    },
    getNodeChecked(ref, data, ids) {
      if (ids.indexOf(data.id) > -1) {
        if (this.$refs[ref] && this.$refs[ref][0]) {
          this.$set(this.$refs[ref][0].getNode(data.id).data, "checked", true);
        }
      }
      if (data.children) {
        data.children.forEach(item => {
          this.getNodeChecked(ref, item, ids);
        });
      }
    },
    handlerCheckedSelf(datas, ids, parentId) {
      datas.forEach(item => {
        item.checked = false;
        item.showChildren = false;
        if (item.type === 1) {
          item.selfType = 0;
        } else if (item.type === 0) {
          item.selfType = 1;
        }
        // if(ids.indexOf(item.id) > -1){
        // 	let ref = item.id +'TreeLazy'
        // 	// type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

        // 	this.handlerCheckedSelf(item.children || [],ids,item.id || null);
        // }  else {
        this.handlerCheckedSelf(item.children || [], ids, item.id || null);
        // }

      });
      return datas;
    },
    //选择员工报单人
    onNodeClick(node, staffType, corpItem, row) {
      console.log(node, staffType, item, row, "点击");
      let item = {}
      let type = "";
      if (!staffType) {
        let selectCorp = this.corpList.filter(item=>item.originData.corpId == this.corpId);
        console.debug('selectCorp',selectCorp,this.corpList)
        item = this.keyword?selectCorp[0]:corpItem;//搜索时取
        type = node.isWeWorkThirdData ||
        item.isWeWorkThirdData ||
        (item.originData &&
            item.originData.wechatBotDTO &&
            item.originData.wechatBotDTO.bindType === 0 &&
            item.originData.wechatBotDTO.wechatType === 0) ? "staff-t" : "staff";
      } else {
        type = staffType;
        item = corpItem
      }
      this.$set(node, "staffType", type);
      console.log(type, "typetypetypetype");
      if (!this.isMultiple) {
        if (type === "updown") {
          let obj = JSON.parse(JSON.stringify(node));
          // let flag = obj.checked;
          // 	// selfType -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
          if (node.selfType == 2) {
            this.getAllNodeId(this.treeData, "").then(res => {

            });
            this.handlerUpDown(this.updownDateTree, node);
            this.$set(node, "checked", true);
            this.$emit("onNodeClick", node, type, item, row);
          } else {
            if (node.wxOpenId && node.organizationId && staffType === "updown") {
              this.getAllNodeId(this.treeData, "").then(res => {

              });
              this.handlerUpDown(this.updownDateTree, node);
              this.$set(node, "checked", true);
              this.$emit("onNodeClick", node, type, item, row);
            }
          }

        } else {
          this.handlerUpDown(this.updownDateTree, node);
          this.getAllNodeId(this.treeData, "").then(res => {
            this.$set(node, "checked", true);
            this.clientId = node.id;
            if (type === "staff-t") {
            }
            this.$emit("onNodeClick", node, type, item, row);
          });
        }
      } else {
        if (type === "updown") {
          if (node.selfType == 2) {
            let obj = JSON.parse(JSON.stringify(node));
            let flag = obj.checked;
            if (staffType === "updown") {
              this.$set(node, "checked", !flag);
              this.$emit("onNodeClick", node, type, item, row);
            }
          } else {
            if (node.wxOpenId && node.organizationId && staffType === "updown") {
              let obj = JSON.parse(JSON.stringify(node));
              let flag = obj.checked;
              if (staffType === "updown") {
                this.$set(node, "checked", !flag);
                this.$emit("onNodeClick", node, type, item, row);
              }
            }
          }
        } else {
          this.$set(node, "checked", !node.checked);
          this.$emit("onNodeClick", node, type, item, row);
        }
      }
    },
    //搜索
    searchStaff() {
      if (this.activeName === "staff") {
        let url = "/api/manager/searchMember?keyWord=" + this.keyword;
        this.FetchGet(url).then(res => {
          // this.$http.get(url).then(res=>{
          if (res.data.code === "0") {
            console.debug("searchMember", res.data.data);
          }
        });
      }
    },
    ergodicClient(type) {
      return new Promise((resolve) => {
        this.selectClient.forEach(client => {
          this.$set(client, "checked", false);
        });
        this.selectClient.forEach(client => {
          //   if (!type){
          if (!this.isMultiple) {
            if (client.id === this.clientValue) {
              this.$set(client, "checked", true);
            }
          } else {
            this.clientValue.forEach(item => {
              if (client.id === item) {
                this.$set(client, "checked", true);
              }
            });
          }
          //   }


        });
        resolve(true);
      });

    },
    // 递归获取所有节点
    getAllNodeId(moduleDataList, type) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          let checkedList = this.isChooseDeptMember?this.checkedList:this.memberInfo4Client.clientInfo
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (type) {
              if (!this.isMultiple) {
                if (moduleDataList[i].id == Number(this.memberInfo4Client.memberId)) {
                  this.$set(moduleDataList[i], "checked", true);
                }
              } else {
                checkedList.forEach(item => {
                  if (moduleDataList[i].id == item.id) {
                    this.$set(moduleDataList[i], "checked", true);
                  }
                });
              }
            }
            if (moduleDataList[i].children) {
              this.getAllNodeId(moduleDataList[i].children, type);
            }
          }
        }
        resolve(true);
      });

    },
    //多选遍历树节点
    ergodicMemberTree(moduleDataList, node) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (moduleDataList[i].children) {
              this.ergodicMemberTree(moduleDataList[i].children, node);
            }
          }
        }
        resolve(true);
      });
    },
    //在触发条件下遍历树节点
    ergodicMemberTree2(moduleDataList, type) {
      if (moduleDataList) {
        for (let i = 0; i < moduleDataList.length; i++) {
          if (moduleDataList[i].checked) {
            if (type === "treeData") {
              moduleDataList[i].staffType = moduleDataList[i].originData &&
              moduleDataList[i].originData.wechatBotDTO &&
              moduleDataList[i].originData.wechatBotDTO.bindType === 0 &&
              moduleDataList[i].originData.wechatBotDTO.wechatType === 0 ? "staff-t" : "staff";
            }
            this.checkMemberList.push(moduleDataList[i]);
            console.log(this.checkMemberList, 23333333);
          }
          if (moduleDataList[i].children) {
            this.ergodicMemberTree2(moduleDataList[i].children, type);
          }
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //搜索成员
    searchEmployee(type) {
      let departId = "";
      console.debug("updown11", this.activeName);
      if (this.activeName == "staff" && this.keyword) {
        if (type !== "load") {
          this.treeData = [];
          this.currentPage = 1;
        }
        let userId = localStorage.getItem("_uid");
        let url = "/portal-api/department/corpListLazy-v2?" + "userId=" + userId + "&corpId=" + this.corpId + "&keyword=" + this.keyword + "&page=" + this.currentPage + "&size=" + this.pageSize;
        this.FetchGet(url).then(res => {
          console.debug("get member", res);
          let list = res && res.list ? res.list : [];
          this.staffHasNext = res.hasNextPage;
          let ids = [];

          list.forEach(item => {
            this.$set(item, "checked", false);
            if (ids.includes(item.id)) {
              this.$set(item, "checked", true);
            }
            if (item.type === 1) {
              item.selfType = 0;
            } else if (item.type === 0) {
              item.selfType = 1;
            }
          });
          this.treeData = [...this.treeData, ...list];
          this.getAllNodeId( this.treeData , "show");
        });
      } else if (this.activeName == "upDown" && this.keyword) {

        this.searchUpAndDown();
      }

    },
    //分页加载更多
    loadStaffMore() {
      this.currentPage += 1;
      this.searchEmployee("load");
    },
    //搜索上下游
    searchUpAndDown() {
      let userId = localStorage.getItem("_uid");
      let url = "/portal-api/upAndDown/searchUpDownMemberOrDepart?userId=" + userId + "&keyword=" + this.keyword;
      this.FetchGet(url).then(res => {
        console.debug("get updown", res);
        if (res.code == 0) {
          let list = res.data ? res.data : [];
          list.forEach(item => {
            let leaf = item.type == 1 ? false : true;
            this.$set(item, "leaf", leaf);
            item.type = "updown";
            this.$set(item, "checked", false);
            if (item.organizationId) {
              item.selfType = 3;
            } else {
              item.selfType = 2;
            }
          });
          this.updownDateTree = list;
          this.getAllNodeId(this.updownDateTree, "show");
        }
      });

    },
    //监听搜索框为空时
    monitorSearchNull() {
      if (this.activeName == "staff" && !this.keyword) {
        this.treeData = [];
        this.getData(null);
      } else if (this.activeName === "upDown" && !this.keyword) {
        this.updownDateTree = [];
        this.loadNode(this.updownNode, this.updownResolve);
      }
    }
  },
  watch: {
    keyword(val) {
      let tree = this.$refs.tree;
      if (tree) {
        tree.forEach(item => {
          item.filter(val);
        });
      }


    }
  }
};
</script>

<style lang="less">
.staff-customer-input {
  height: 42px;
}

.member-contanier {

  .customer-list {
    .customer-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding: 0 0 0 4px;
      cursor: pointer;

      .el-icon-check {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .customer-item:hover {
      background-color: #F6F8FD;
    }
  }

  .load-more-client {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .custom-tree-node-left {
      display: flex;
      align-items: center;
     /* width: 90%;*/

      i {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-right: 6px;
        color: #c0c4cc;
      }

      .data-label {
       /* width: 100%;*/
        height: 28px;
        display: flex;
        align-items: center;
      }
    }

    .el-icon-check {
      font-size: 14px;
      font-weight: 600;
    }

    &.not-allowed {
      cursor: not-allowed !important;
    }
  }

  .el-tabs__nav-wrap::after {
    height: 0px !important;
  }

  ::v-deep.el-tree-node__content {
    &:hover {
      background-color: #F6F8FD;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: white !important;
  }

  .el-tree-node__content {
    height: 30px !important;
  }

  .el-tree-node__content:hover {
    background-color: #F6F8FD !important;
  }

}
.choose-dept-member{
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .custom-tree-node-left {
      display: flex;
      align-items: center;
     /* width: 90%;*/

      i {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-right: 6px;
        color: #c0c4cc;
      }

      .data-label {
        /*width: 100%;*/
        height: 28px;
        display: flex;
        align-items: center;
      }
    }

    .el-icon-check {
      font-size: 14px;
      font-weight: 600;
    }

    &.not-allowed {
      cursor: not-allowed !important;
    }
  }
}
.staff-customer {
  min-height: 300px;

  .el-tree {

    .el-tree-node__expand-icon {
      display: none;
      font-size: 14px;
    }

    .el-icon-caret-right {
      &.down {
        transform: rotate(90deg);
      }

      &.right {
        transform: rotate(0)
      }
    }

    &.updown-tree {
      .el-tree-node__expand-icon {
        display: inline-block;
      }
    }
  }
}
</style>