<template>
    <div
        id="statistics"
        :style="
            this.$parent.testDrawerVisibleP || this.$parent.botHeathlyDrawerVisible
                ? 'width:calc(100vw - 490px)'
                : 'width:calc(100vw - 64px)'
        "
    >
         <page-top-header style="min-width: 1095px">
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenalzhandianshujutongji "></i>
                <span class="page-name">{{ activePageInfo.headerName}}</span>
                <el-select
                    filterable
                    v-model="activeScopeValue"
                    style="width: 150px;margin-right: 10px"
                    @change="selectScopeChange"
                    placeholder="请选择"
                >
                    <el-option-group
                        v-for="group in cOptions"
                        :key="group.label"
                        :label="group.label"
                    >
                        <el-option
                            v-for="item in group.options"
                            :key="item.scope + '__' + item.type"
                            :label="item.name"
                            :value="item.scope + '__' + item.type"
                        >
                        </el-option>
                    </el-option-group>
                </el-select>
                <div class="input-divs " @click="openStaffPopup(item,index)">
                   <div v-if="memberInfo4Client.clientInfo && memberInfo4Client.clientInfo.length > 0" class="one-column-ellipsis">
                        <span class="have-checked-intent" v-for="(item,index) in memberInfo4Client.clientInfo" :key="index">
                            <!-- 0:部门，1:员工，2:上下游部门，3:上下游成员 -->
                            <span
                                class="data-label"
                                v-if="item.selfType === 0 || item.selfType === 1">
                                <span v-if="item.staffType !== 'staff-t'">{{item.label}}</span>
                                <span v-else>
                                    <open-data :type="item.selfType == 0 ? 'departmentName' : 'userName'" :openid="item.label"></open-data>
                                </span>
                            </span>
                            <span v-else>{{item.name}}</span>
                            <span v-if="index+1 <  memberInfo4Client.clientInfo.length">,</span>
                        </span>
                        
                   </div>
                    <div class="placeholder-text" v-else>全部咨询者</div>
                    <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <div slot="top-header-right" class="top-header-right">
               
                <div class="dateSelection">
                    <!-- <span
                        :class="currentDate == 'yesterday' ? 'isActive' : ''"
                        @click="yesterdayData"
                        >昨日</span
                    >
                    <span
                        :class="currentDate == 'today' ? 'isActive' : ''"
                        @click="todayData"
                        >今日</span
                    >
                    <span
                        :class="currentDate == 'lastWeek' ? 'isActive' : ''"
                        @click="lastWeekData"
                        >近7日</span
                    >
                    <span
                        :class="currentDate == 'lastMonth' ? 'isActive' : ''"
                        @click="lastMonthData"
                        >近30日</span
                    >
                    <el-date-picker
                        v-model="dateSeleteData.value"
                        style="width: 400px"
                        @change="datePickerChange"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker> -->
                    <fastChooseDatePicker ref="fastChooseDatePicker" v-model="dateSeleteData.value" @changeDate="datePickerChange" :isSwitch="true" :type.sync="fastChooseDatePickerType" @changeDateType="changeDateType" :timeType="'static'"></fastChooseDatePicker>
                    <el-button
                        size="mini"
                        @click="exportData"
                        type="primary"
                        :loading="dataIsReady == 1"
                        >{{ exporttext[dataIsReady] }}</el-button
                    >
                </div>
                   
            </div>
        </page-top-header>
        <div class="content-main">
            <div class="statistics-title">
                <div
                    class="other-rate mgr-15"
                    v-loading="loading_1_top + loading_1_bottom == 2"
                >
                    <div class="title">
                        <span>总接待人数</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，与机器人有过对话的用户数。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div v-if="_totalNum != 'empty'" class="number">
                        {{ _totalNum }}
                    </div>
                    <div v-if="_totalNum == 'empty'" class="number font14">
                        暂无数据
                    </div>
                    <div class="line"></div>
                    <div class="title">
                        <span>总接待会话数</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，与机器人有过对话的总对话数。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div class="number" v-if="_totalSessionNum != 'empty'">
                        {{ _totalSessionNum }}
                    </div>
                    <div
                        class="number font14"
                        v-if="_totalSessionNum == 'empty'"
                    >
                        暂无数据
                    </div>
                </div>
                <div v-if="false" class="other-rate mgr-15" v-loading="loading">
                    <div class="title">
                        <span>转人工率</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，每一个用户id首次消息发送时间与机器人首次回复消息的时间差/接待总人数。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div class="number" v-if="!firstResponseTimeEmpty">
                        {{ firstResponseTime.respTime
                        }}<span v-if="firstResponseTime.respTime">%</span
                        ><span style="color: #ffffff">S</span>
                    </div>
                    <div class="number font14" v-if="firstResponseTimeEmpty">
                        暂无数据
                    </div>
                    <div class="line"></div>
                    <div class="title">
                        <span>转工单率</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，用户消息与机器人首条回复消息的时间差总和/总对话轮次。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div class="number" v-if="!averageResponseTimeEmpty">
                        {{ averageResponseTime.respTime
                        }}<span v-if="averageResponseTime.respTime">%</span>
                    </div>
                    <div class="number font14" v-if="averageResponseTimeEmpty">
                        暂无数据
                    </div>
                </div>
                <div v-loading="loading_2 == 1" class="message-number mgr-15">
                    <div class="title">
                        <span>咨询消息数</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，机器人接收到来自用户的消息数。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div
                        class="number"
                        v-if="messageNumberChartDataEmpty == false"
                    >
                        {{ messageNumberChartData.totalNum }}
                    </div>
                    <ve-line
                        :data="messageNumberChartData"
                        :data-empty="messageNumberChartDataEmpty"
                        :settings="messageNumberChartSettings"
                        width="100%"
                        height="150px"
                        :legend-visible="false"
                        :grid="grid"
                        :xAxis="xAxis"
                        :yAxis="yAxis"
                        :colors="messageNumberColors"
                    ></ve-line>
                </div>
                <div v-loading="loading_3 == 1" class="recognition-rate mgr-15">
                    <div class="title">
                        <span>识别率</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，由机器人接待的用户中，可被识别的消息数/客户消息数*100%. 说明：用户数据太少时，由于识别率不具备参考性，识别率暂不计算，显示为暂无数据。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div
                        class="number"
                        v-if="recognitionRateChartDataEmpty == false"
                    >
                        {{ recognitionRateChartData.totalRate
                        }}<span v-if="recognitionRateChartData.totalRate"
                            >%</span
                        >
                    </div>
                    <ve-line
                        :data="recognitionRateChartData"
                        :data-empty="recognitionRateChartDataEmpty"
                        :settings="recognitionRateChartSettings"
                        :extend="recognitionRatechartExtend"
                        width="100%"
                        height="150px"
                        :legend-visible="false"
                        :grid="grid"
                        :xAxis="xAxis"
                        :yAxis="yAxis"
                        :colors="recognitionRatecolors"
                    ></ve-line>
                </div>
                <div
                    class="other-rate"
                    v-loading="loading_4_top + loading_4_bottom == 2"
                >
                    <div class="title">
                        <span>机器人平均首次响应时长</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，每一个用户id首次消息发送时间与机器人首次回复消息的时间差/接待总人数。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div class="number" v-show="_firstResponseSec != null">
                        {{ _firstResponseSec }}<span>s</span>
                    </div>
                    <div
                        class="number font14"
                        v-show="_firstResponseSec == null"
                    >
                        暂无数据
                    </div>
                    <div class="line"></div>
                    <div class="title">
                        <span>机器人平均响应时长</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="选择的统计时间内，用户消息与机器人首条回复消息的时间差总和/总对话轮次。"
                            placement="top-start"
                        >
                            <em class="el-icon-warning-outline"></em>
                        </el-tooltip>
                    </div>
                    <div class="number" v-show="_avResponseSec != null">
                        {{ _avResponseSec }}<span>s</span>
                    </div>
                    <div class="number font14" v-show="_avResponseSec == null">
                        暂无数据
                    </div>
                </div>
            </div>
            <div
                v-loading="loading_m_1 == 1"
                id="people-content"
                class="people-content"
            >
                <div class="title">
                    <span>总接待人数</span>
                </div>
                <ve-histogram
                    :key="histogramNum"
                    :data="peoplelargechartData"
                    :data-empty="peopleNumberchartDataEmpty"
                    :settings="peoplelargechartSettings"
                    width="100%"
                    height="400px"
                    :grid="{ right: 30 }"
                    :colors="peoplelargecolors"></ve-histogram>
            </div>
            <div v-loading="loading_m_2 == 1" class="message-content">
                <div class="title">
                    <span>客户消息数</span>
                </div>
                <ve-histogram
                    :key="histogramNum"
                    :data="messagelargechartData"
                    :data-empty="messagelargechartDataEmpty"
                    width="100%"
                    height="400px"
                    :settings="messagelargechartSettings"
                    :colors="messagelargecolors"></ve-histogram>
            </div>
            <div class="satisfaction">
                <div class="satisfaction-content">
                    <div class="title with-handle-title">
                        <span>问题类型咨询占比</span>
                        <span
                            @click="setTypeDialogVisible = true"
                            class="el-icon-s-tools"
                        ></span>
                    </div>
                    <div
                        v-show="!qwTypeEchartsIsEmpty"
                        v-loading="loading_9 == 1"
                        id="qwTypeEcharts1"
                        :style="{
                            width: '500px',
                            height: '480px',
                            margin: '0 auto',
                        }"
                    ></div>
                    <div
                        class="number font14"
                        style="margin-top: 150px"
                        v-show="qwTypeEchartsIsEmpty"
                    >
                        暂无数据
                    </div>
                </div>
                <div class="satisfaction-content satisfaction-content-right">
                    <div class="title with-handle-title">
                        <span>答案反馈占比</span>
                        <el-button
                            v-show="activeTypeIsBot"
                            @click="goToTrainList('feedbackNote')"
                            size="mini"
                            type="primary"
                            round
                            >去优化</el-button
                        >
                    </div>
                    <div
                        v-loading="loading_11 == 1"
                        id="feedbackEcharts"
                        :style="{
                            width: '460px',
                            height: '480px',
                            margin: '0 auto',
                        }"
                    ></div>
                    <div
                        class="number font14"
                        style="margin-top: 150px"
                        v-show="feedbackEchartsIsEmpty"
                    >
                        暂无数据
                    </div>
                </div>
            </div>
            <div class="satisfaction">
                <div class="satisfaction-content">
                    <div class="title with-handle-title">
                        <span>满意度统计</span>
                    </div>
                    <div
                        v-show="!satisfTypeEchartsIsEmpty"
                        v-loading="loading_5 == 1"
                        id="satisfTypeEcharts1"
                        :style="{
                            width: '460px',
                            height: '480px',
                            margin: '0 auto',
                        }"
                    ></div>
                    <div
                        class="number font14"
                        style="margin-top: 150px"
                        v-show="satisfTypeEchartsIsEmpty"
                    >
                        暂无数据
                    </div>
                </div>
                <div class="satisfaction-content satisfaction-content-right">
                    <div class="title with-handle-title">
                        <span>不满意原因统计</span>
                    </div>
                    <div
                        v-loading="loading_6 == 1"
                        v-show="!noSatisfTypeEchartsIsEmpty"
                        id="noSatisfTypeEcharts1"
                        :style="{
                            width: '460px',
                            height: '480px',
                            margin: '0 auto',
                        }"
                    ></div>
                    <div
                        class="number font14"
                        style="margin-top: 150px"
                        v-show="noSatisfTypeEchartsIsEmpty"
                    >
                        暂无数据
                    </div>
                </div>
            </div>
           
            <div
                v-loading="loading_18 == 1"
                id="people-content"
                class="people-content qa-consult"
            >
                <div class="title">
                    <span>问题咨询排名</span>
                    <i class="el-icon-s-tools" @click="openQaConsult"></i>
                </div>
                <ve-histogram
                    :key="histogramNum"
                    :data="qaConsultechartData"
                    :data-empty="qaConsulterchartDataEmpty"
                    :settings="qaConsultechartSettings"
                    width="100%"
                    height="400px"
                    :grid="{ right: 30 }"
                    :colors="peopleNumberColors"
                    :extend="consulteExtend"
                ></ve-histogram>
            </div>
            <div class="satisfaction-list">
                <div class="satisfaction-content-list">
                    <div class="title with-handle-title">
                        <span>意图咨询TOP30</span>
                    </div>
                    <TopTen
                        :tableData="intentTopTen"
                        :loading="loading_10_left == 1"
                        tableType="intent"
                    />
                </div>
                <div
                    class="satisfaction-content-list satisfaction-content-right"
                >
                    <div class="title with-handle-title">
                        <span>未识别问题TOP30</span>
                        <el-button
                            v-show="activeTypeIsBot"
                            size="mini"
                            @click="goToTrainList('question')"
                            type="primary"
                            round
                            >去标注</el-button
                        >
                    </div>
                    <TopTen
                        :tableData="qwTopTen"
                        :loading="loading_10_right == 1"
                        tableType="qw"
                    />
                </div>
            </div>
            <div v-show="showMap" v-loading="loading_13" class="">
                <Area
                    :scope="formatterActiveScopeValue(activeScopeValue)"
                    :areaList="areaList"
                    :scopeType="activeScopeType"
                    @reload13="reload13"
                    ref="areaComponent"
                />
            </div>
            <div class="" style="height: 100px"></div>
        </div>
        <el-dialog
            title="设置问题类型"
            :visible.sync="setTypeDialogVisible"
            width="600px"
        >
            <div v-if="setTypeDialogVisible">
                <SetType
                    :scope="formatterActiveScopeValue(activeScopeValue)"
                    :scopeType="activeScopeType"
                    @cancelSaveSetType="cancelSaveSetType"
                    @saveSetType="saveSetType"
                />
            </div>
        </el-dialog>
      
        <!-- 选择咨询者 -->
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
            <div slot="popup-name">选择咨询者</div>
            <div slot="popup-con" class="member-contanier">
                <div class="choose-style">
                    <div>选择咨询者类型</div>
                    <div class="radio-box">
                        <el-radio v-model="chooseStyle" label="all">全部</el-radio>
                        <el-radio v-model="chooseStyle" label="self">自定义</el-radio>
                    </div>
                </div>
                <staffCustomer
                    v-if="chooseStyle === 'self'"
                    :isMultiple="true"
                    :selectClient="selectClient"
                    @onNodeClick="onNodeClick"
                    :clientValue="clientValue"
                    :memberInfo4Client="memberInfo4Client"
                    ref="staffCustomer"
                ></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogMemberTree=false" plain size="small" class="cancel-btn">取 消</el-button>
                <el-button type="primary" @click="preserStaff" size="small" class="confirm-btn">确定</el-button>
            </div>
        </popup>
        <setStatisticalClassifi 
            :activeScopeValue="activeScopeValue"
            :activeScopeType="activeScopeType"
            :statisticalClassifiVisible.sync="statisticalClassifiVisible" 
            @updateSetStatisticalClassifi="updateSetStatisticalClassifi">
        </setStatisticalClassifi>
    </div> 
</template>
<script>
import OpenData from "../../components/openData.vue";
import pageTopHeader from '../../components/page-top-header.vue'
import VeLine from "v-charts/lib/line.common";
import Histogram from "v-charts/lib/histogram.common";
import Area from "./component/Area.vue";
import SetType from "./component/SetType.vue";
import TopTen from "./component/TopTen.vue";
import "v-charts/lib/style.css";
import fastChooseDatePicker from '../botIndex/component/fastChooseDatePicker.vue'
import Popup from '../../components/popup.vue';
import staffCustomer from '../../components/staffCustomer.vue'
import setStatisticalClassifi from './component/setStatisticalClassifi.vue'
export default {
    props: ["testDrawerVisibleP"],
    components: { VeLine, Histogram, Area, SetType, TopTen,pageTopHeader,fastChooseDatePicker,Popup ,staffCustomer,setStatisticalClassifi,OpenData},
    data() {
        (this.grid = {
            show: true,
            top: "0",
            left: "-22%",
            right: "-13%",
            backgroundColor: "#ffffff",
            borderColor: "#fff",
        }),
            (this.peopleNumbergrid = {
                show: true,
                top: "0",
                left: "0",
                backgroundColor: "#ffffff",
                borderColor: "#fff",
            }),
            (this.peopleNumberxAxis = {
                type: "category",
                show: false,
            });
        this.xAxis = {
            type: "category",
            show: false,
        };
        this.grid = {
            right: 0,
            top: 5,
            left: -24,
        };
        this.yAxis = {
            type: "value",
            show: false,
        };
        this.recognitionRatecolors = ["#fec70a"];
        this.messageNumberColors = ["#a626e8"];
        this.peopleNumberColors = ["#366AFF"];
        this.peoplelargecolors = ["#366AFF", "#ffc922", "#ffc922"];
        this.messagelargecolors = ["#a626e8", "#ffc922", "#ffc922"];
        this.peoplelargechartSettings = {
            showLine: ["满意率"],
            axisSite: { right: ["满意率"] },
            yAxisType: ["KMB", "percent"],
            yAxisName: ["客户数", ""],
        };
        this.messagelargechartSettings = {
            showLine: ["识别率"],
            axisSite: { right: ["识别率"] },
            yAxisType: ["KMB", "percent"],
            yAxisName: ["            客户消息数", "识别率"],
        };
        this.qaConsultechartSettings = {
            showLine: [""],
            axisSite: {  },
            yAxisType: [""],
            yAxisName: [""],
        };
        return {
            cOptions: [],
            exporttext: ["数据导出", "生成中..."],
            dataIsReady: 0,
            newStartTime: "",
            newEndTime: "",
            // 新版选择渠道 start
            activeScopeValue: "", // 新版选择渠道scope
            activeScopeType: "", // 新版选择渠道type
            activeScopeId:"",   //新版选择渠道的id
            botScopeValue:"",  //新版机器人渠道scope
            activeTypeIsBot:false, //新版判断渠道是否是机器人
            appTypeImageList: [
                // 新版选择渠道icon list
                "https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/xiaochengxu.e4adc93b.jpeg",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/webchat.a051ad70.png",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
            ],
            scopeList: [],
            areaList: [],
            // 新版选择渠道 end

            // loading start
            loading_1_top: 1,
            loading_1_bottom: 1,
            loading_2: 1,
            loading_3: 1,
            loading_m_1: 1,
            loading_m_2: 1,
            loading_5: 1,
            loading_6: 1,
            loading_10_left: 1,
            loading_10_right: 1,
            loading_11: 1,
            loading_9: 1,
            loading_18:0,
            // top Number
            _totalNum: "empty",
            _totalSessionNum: "empty",
            satisfTypeEchartsIsEmpty: false,
            noSatisfTypeEchartsIsEmpty: false,
            qwTypeEchartsIsEmpty: false,
            feedbackEchartsIsEmpty: false,
            _avResponseSec: 0,
            _firstResponseSec: 0,

            activePageInfo: {
                headerName: "统计分析",
            },
            activeTabName: "question",
            loading: true,
            loading2: false,
            dateSeleteData: [{ calue: "" }],
            currentDate: "diy",
            firstResponseTimeEmpty: false,
            firstResponseTime: { respTime: "" },
            averageResponseTimeEmpty: false,
            averageResponseTime: { respTime: "" },
            peopleNumberchartDataEmpty: false,
            peopleNumberchartData: {
                totalNum: "",
                columns: ["日期", "访问用户"],
                rows: [{ 日期: "", 访问用户: "" }],
            },
            messageNumberChartDataEmpty: false,
            messageNumberChartData: {
                totalNum: "",
                columns: ["日期", "客户消息数"],
                rows: [{ 日期: "", 客户消息数: "" }],
            },
            messageNumberChartSettings: {
                area: { show: true },
                label: { show: false },
                labelLine: { show: false },
            },
            recognitionRateChartDataEmpty: false,
            recognitionRateChartData: {
                totalRate: "",
                indicatorName: "",
                columns: ["日期", "识别率"],
                rows: [{ 日期: "", 识别率: null }],
            },
            recognitionRatechartExtend: {
                series: {
                    center: [112, 92],
                    areaStyle: { color: "#fffae2" },
                    lineStyle: { color: "#ffc922" },
                },
            },
            recognitionRateChartSettings: {
                area: { show: true },
                label: { show: false },
                labelLine: { show: false },
            },
            peoplelargechartData: {
                columns: ["日期", "访问用户"],
                rows: [{ 日期: "", 访问用户: "" }],
            },
            messagelargechartDataEmpty: false,
            messagelargechartData: {
                columns: ["日期", "访问用户", "识别率"],
                rows: [{ 日期: "", 访问用户: "", 识别率: "" }],
            },
            satisfactionchartDataEmpty: false,
            satisfactionchartData: {
                columns: ["满意度调查", "访问次数"],
                rows: [
                    { 满意度调查: "满意次数", 访问次数: "" },
                    { 满意度调查: "不满意次数", 访问次数: "" },
                ],
            },
            dissatisfiedchartDataEmpty: false,
            dissatisfiedchartData: {
                columns: ["不满意统计", "访问次数"],
                rows: [{ 不满意统计: "", 访问次数: "" }],
            },
            setTypeDialogVisible: false,
            intentTopTen: [],
            qwTopTen: [],
            showMap: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 30*24*60*60*1000 || time.getTime() > Date.now();
                },
            },
            fastChooseDatePickerType:"datetimerange",
            fastChooseDatePickerTypeS:"datetimerange",
            statisticalClassifiVisible:false,
            botId:'',
            dialogMemberTree:false,
            selectClient:[],
            memberInfo4Client:{
                clientInfo:[], // 选中数据
            },
            departmentId:[],
            memberId:[],
            upDownDepartmentId:[],
            upDownMemberId:[],
            chooseStyle:"all",
            checkedList:[],
            histogramNum:1,
            qaConsultechartData:{
                columns: ["分类","数量"],
                rows: [{ 分类: "",数量:"" }],
            },
            qaConsulterchartDataEmpty:false,
            consulteExtend:{
                series: {
                   barMaxWidth:80,
                   type:"bar",
                   grid: {
                        left: '1%',
                        right: '1%',
                        containLabel: true,
                    },
                },
                xAxis : {
                    //设置文本过长超出隐藏...表示
                    axisLabel:{
                        margin: 8,
                        formatter:function(params){
                            var val="";
                            if(params.length >4){
                                val = params.substr(0,4)+'...';
                                return val;
                            }else{
                                return params;
                            }
                        }
                    },
                },
            }
        };
    },
    methods: {
        changeDateType(type){
            this.fastChooseDatePickerTypeS = type;
        },
        preserStaff(){
            if(this.chooseStyle === 'all'){
                this.departmentId = []
                this.memberId = []
                this.upDownDepartmentId = []
                this.upDownMemberId = []
                this.checkedList = []
            }
            this.memberInfo4Client.clientInfo = this.checkedList || [];
            this.dialogMemberTree = false;
            this.getDataCell(this.newStartTime,this.newEndTime);
        },
        openStaffPopup(){
            this.dialogMemberTree = true;
        },
       onNodeClick(node,type,memberInfo){
            this.departmentId = []
            this.memberId = []
            this.upDownDepartmentId = []
            this.upDownMemberId = []
            if(node.checked){
                this.checkedList.push(node);
            } else {
                 this.checkedList.forEach((v,i) => {
                    if(node.id == v.id){
                        this.checkedList.splice(i,1)
                    }
                 })
            }
            this.$refs.staffCustomer.checkMemberList = []
            console.log(this.$refs.staffCustomer.treeData,11111111);
            // this.$refs.staffCustomer.ergodicMemberTree2(this.$refs.staffCustomer.updownDateTree,'updownDateTree')
            // this.$refs.staffCustomer.ergodicMemberTree2(this.$refs.staffCustomer.treeData,'treeData');
            this.checkedList = this.handlerArr(this.checkedList)
            // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
             this.checkedList.forEach((item,index) => {
                if(item.selfType === 0){
                    this.departmentId.push(item.id)
                } else  if(item.selfType === 1){
                    this.memberId.push(item.id)
                } else  if(item.selfType === 2){
                    this.upDownDepartmentId.push(item.id)
                } else  if(item.selfType === 3){
                    this.upDownMemberId.push(item.id)
                }
            })
            console.log( this.checkedList,'------');
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        openQaConsult(){
            this.statisticalClassifiVisible = true;
        },
        updateSetStatisticalClassifi(){
            this.getStatisticsDataNew(this.newStartTime,this.newEndTime, 18, "indicator");
        },
        formatterActiveScopeValue(activeScopeValue) {
            return activeScopeValue.split("__")[0];
        },
        // 导出数据
        exportData() {
            let href =
                this.requestUrl.statistics.exportStatic +
                this.formatterActiveScopeValue(this.activeScopeValue) +
                "?scopeType=" +
                this.activeScopeType +
                "&startTime=" +
                this.newStartTime +
                "&endTime=" +
                this.newEndTime;


            let params = {
                scope:this.formatterActiveScopeValue(this.activeScopeValue),
                scopeType: this.activeScopeType,
                startTime:this.newStartTime,
                endTime:this.newEndTime,
            }
            if(this.chooseStyle === 'all'){
                params.departmentId = [];
                params.memberId = [];
                params.upDownDepartmentId = [];
                params.upDownMemberId = [];
            } else {
                params.departmentId = this.departmentId
                params.memberId = this.memberId
                params.upDownDepartmentId = this.upDownDepartmentId
                params.upDownMemberId = this.upDownMemberId
            }
            this.dataIsReady = 1;
            this.FetchPost(this.requestUrl.statistics.exportStatic,params)
                .then((res) => {
                    if (res.code === "0") {
                        window.location.href = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.dataIsReady = 0;
                })
                .catch((err) => {
                    this.dataIsReady = 0;
                    this.$message(err);
                });
        },
        // 不满意原因统计
        noSatisfTypeEcharts(qwTypedata) {
            // 基于准备好的dom，初始化echarts实例
            let qwTypmyChart = this.$echarts.init(
                document.getElementById("noSatisfTypeEcharts1")
            );
            // 绘制图表
            qwTypmyChart.setOption({
                title: {
                    text: "",
                    subtext: "",
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    top: "left",
                    left: "0%",
                },
                color: [
                    "#7EC0EE",
                    "#FF9F7F",
                    "#E066FF",
                    "#C0FF3E",
                    "#FFD700",
                    "#C9C9C9",
                    "#b4532a",
                    "#f173ac",
                    "#ffe600",
                    "#f36c21",
                    "#ffd400",
                    "#c99979",
                    "#90d7ec",
                    "#f2eada",
                    "#f0dc70",
                    "#decb00",
                    "#de773f",
                    "#2585a6",
                    "#fcf16e",
                    "#b7704f",
                    "#4e72b8",
                    "#feeeed",
                    "#feeeed",
                ],
                series: [
                    {
                        name: "不满意原因",
                        type: "pie",
                        center: ["50%", "50%"],
                        radius: ["40%", "70%"],
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2,
                        },
                        data: qwTypedata,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                                normal: {
                                    label: {
                                        show: true,
                                        formatter: "{b} : {c} ({d}%)",
                                    },
                                    labelLine: { show: true },
                                },
                            },
                        },
                    },
                ],
            });
        },
        // 满意度统计
        satisfTypeEcharts(qwTypedata) {
            // 基于准备好的dom，初始化echarts实例
            let qwTypmyChart = this.$echarts.init(
                document.getElementById("satisfTypeEcharts1")
            );
            // 绘制图表
            qwTypmyChart.setOption({
                title: {
                    text: "",
                    subtext: "",
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    top: "left",
                    left: "0%",
                },
                color: [
                    "#7EC0EE",
                    "#FF9F7F",
                    "#E066FF",
                    "#C0FF3E",
                    "#FFD700",
                    "#C9C9C9",
                ],
                series: [
                    {
                        name: "满意度",
                        type: "pie",
                        radius: ["40%", "70%"],
                        center: ["50%", "50%"],
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2,
                        },
                        data: qwTypedata,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                                normal: {
                                    label: {
                                        show: true,
                                        formatter: "{b} : {c} ({d}%)",
                                    },
                                    labelLine: { show: true },
                                },
                            },
                        },
                    },
                ],
            });
        },
        // 问题类型
        qwTypeEcharts(qwTypedata) {
            // 基于准备好的dom，初始化echarts实例
            let qwTypmyChart = this.$echarts.init(
                document.getElementById("qwTypeEcharts1")
            );
            // 绘制图表
            qwTypmyChart.setOption({
                title: {
                    text: "",
                    subtext: "",
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    top: "left",
                    left: "0%",
                    type: 'scroll', // 滚动
                    pageIconSize: 14, //这当然就是按钮的大小
                },
                color: [
                    "#7EC0EE",
                    "#FF9F7F",
                    "#E066FF",
                    "#C0FF3E",
                    "#FFD700",
                    "#C9C9C9",
                    "#b4532a",
                    "#f173ac",
                    "#ffe600",
                    "#f36c21",
                    "#ffd400",
                    "#c99979",
                    "#90d7ec",
                    "#f2eada",
                    "#f0dc70",
                    "#decb00",
                    "#de773f",
                    "#2585a6",
                    "#fcf16e",
                    "#b7704f",
                    "#4e72b8",
                    "#feeeed",
                    "#feeeed",
                ],
                series: [
                    {
                        name: "问题类型",
                        type: "pie",
                        radius: ["35%", "65%"],
                        center: ["50%", "60%"],
                        avoidLabelOverlap:true,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2,
                        },
                        label:{
                            normal:{
                                 formatter(v) {
                                        console.log(v,'---');
                                        return v.data.name.slice(0,5) + '...'
                                    }
                            }
                        },
                        data: qwTypedata,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                                normal: {
                                    label: {
                                        show: true,
                                        formatter: "{b} : {c} ({d}%)",
                                    },
                                    labelLine: { show: true },
                                },
                            },
                        },
                    },
                ],
            });
        },
        // 答案反馈占比
        feedbackEcharts(data) {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("feedbackEcharts")
            );
            // 绘制图表
            myChart.setOption({
                title: {
                    text: "",
                    subtext: "",
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    top: "left",
                    left: "0%",
                },
                color: [
                    "#7EC0EE",
                    "#FF9F7F",
                    "#FFD700",
                    "#C9C9C9",
                    "#E066FF",
                    "#C0FF3E",
                ],
                series: [
                    {
                        name: "反馈",
                        type: "pie",
                        center: ["50%", "60%"],
                        radius: ["35%", "65%"],
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2,
                        },
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                                normal: {
                                    label: {
                                        show: true,
                                        formatter: "{b} : {c} ({d}%)",
                                    },
                                    labelLine: { show: true },
                                },
                            },
                        },
                    },
                ],
            });
        },
        // 跳转去标注/优化
        goToTrainList(type) {
            this.$router.push("/main/trainList?type=" + type+'&BotId='+this.activeScopeId+'&scope='+this.botScopeValue);
        },
        // 修改统计维度
        selectScopeChange(value) {
            this.activeTypeIsBot = false
            this.activeScopeId = ''
            this.botScopeValue=""
            this.scopeList.forEach((item) => {
                if (item.scope + "__" + item.type == value) {
                    this.activeScopeType = item.type;
                    this.botId = item.id;
                }
                if (item.type==='BOT'&&item.scope + "__" + item.type == value){
                    this.activeScopeId = item.id
                    this.activeTypeIsBot = true
                    this.botScopeValue=item.scope
                    console.log(item)
                }
            });
            this.changeGetData();
        },
        // 获取统计维度列表
        getStatisticsScopes() {
            this.cOptions = [
                {
                    label: "网站接入",
                    options: [],
                },
                {
                    label: "企业微信",
                    options: [],
                },
                {
                    label: "微信小程序",
                    options: [],
                },
                {
                    label: "微信公众号",
                    options: [],
                },
                {
                    label: "钉钉",
                    options: [],
                },
                {
                    label: "微信客服",
                    options: [],
                },
                {
                    label: "钉钉群",
                    options: [],
                },
                {
                    label: "群模板",
                    options: [],
                },
                {
                    label: "APP",
                    options: [],
                },
                {
                    label: "钉钉单聊机器人",
                    options: [],
                },
                {
                    label: "机器人",
                    options: [],
                },
                {
                    label: "机器人",
                    options: [],
                },
                {
                    label: "飞书",
                    options: [],
                },
            ];
            this.FetchGet(this.requestUrl.statistics.getStatisticsScopes).then(
                (res) => {
                    if (res.code === "0") {
                        this.scopeList = res.data;
                        this.scopeList.forEach((item) => {
                            if (item.type == "APPLET") {
                                this.cOptions[2].options.push(item);
                            } else if (item.type == "BOT") {
                                this.cOptions[10].options.push(item);
                            } else {
                                switch (item.appType) {
                                    case 0:
                                        this.cOptions[1].options.push(item);
                                        break;
                                    case 1:
                                        this.cOptions[3].options.push(item);
                                        break;
                                    case 2:
                                        // this.cOptions[0].options.push(item);
                                        break;
                                    case 3:
                                        this.cOptions[4].options.push(item);
                                        break;
                                    case 4:
                                        this.cOptions[0].options.push(item);
                                        break;
                                    case 5:
                                        this.cOptions[5].options.push(item);
                                        break;
                                    case 6:
                                        // this.cOptions[6].options.push(item);
                                        break;
                                    case 9:
                                        this.cOptions[9].options.push(item);
                                        break;
                                    case 10:
                                        this.cOptions[12].options.push(item);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });

                        this.activeScopeValue =
                            this.scopeList[0].scope +
                            "__" +
                            this.scopeList[0].type;
                        this.activeScopeType = this.scopeList[0].type;
                        this.botId = this.scopeList[0].id
                        // this.yesterdayData();
                        this.getDataCell(this.newStartTime,this.newEndTime);
                        // this.getIntentList();
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 获取统计数据
        // 统计指标 0：接待人数 1：消息数 2：识别率 3：平均首次响应时长 4：平均响应时长 5：满意度
        // 6：不满意原因 7：接待会话数 8：转工单率 9：咨询问题类型 10：意图排行 11：答案反馈占比 12：未识别问题 13：地域分布
        // 15：不识别推荐点击排名 16：智能引导点击排名17：知识库更新情况 18：问题咨询排名
        getStatisticsDataNew(startTime, endTime, indicator, type) {
            switch (indicator) {
                case 0:
                    this.loading_1_top = 1;
                    break;
                case 7:
                    this.loading_1_bottom = 1;
                    break;
                case 1:
                    this.loading_2 = 1;
                    break;
                case 2:
                    this.loading_3 = 1;
                    break;
                case 3:
                    this.loading_4_top = 1;
                    break;
                case 4:
                    this.loading_4_bottom = 1;
                    break;
                case "0,7":
                    this.loading_m_1 = 1;
                    break;
                case "1,2":
                    this.loading_m_2 = 1;
                    break;
                case 5:
                    this.loading_5 = 1;
                    break;
                case 6:
                    this.loading_6 = 1;
                    break;
                case 10:
                    this.loading_10_left = 1;
                    break;
                case 9:
                    this.loading_9 = 1;
                    break;
                case 11:
                    this.loading_11 = 1;
                    break;
                case 12:
                    this.loading_10_right = 1;
                    break;
                case 13:
                    this.loading_13 = 1;
                    break;
                case 18:
                    this.loading_18 = 1;
                    break;
                default:
                    break;
            }
            let param = {
                scope: this.formatterActiveScopeValue(this.activeScopeValue),
                scopeType: this.activeScopeType,
                startTime: startTime,
                endTime: endTime,
            };
            // 单个数据查询
            if (type == "indicator") {
                param.indicator = indicator;
            }
            // 联合数据查询
            if (type == "indicators") {
                param.indicators = indicator;
            }
            if(this.fastChooseDatePickerType === 'monthrange'){
                if( this.$refs.fastChooseDatePicker){
                    this.$refs.fastChooseDatePicker.handlerMonthClose();
                }
                if(this.fastChooseDatePickerTypeS === 'monthrange'){
                    param.month = true;
                } else {
                    param.month = false;
                }
            } else {
                param.month = false;
            }
            if(this.chooseStyle === 'all'){
                param.departmentId = [];
                param.memberId = [];
                param.upDownDepartmentId = [];
                param.upDownMemberId = [];
            } else {
                param.departmentId = this.departmentId
                param.memberId = this.memberId
                param.upDownDepartmentId = this.upDownDepartmentId
                param.upDownMemberId = this.upDownMemberId
            }
            this.FetchPost(
                this.requestUrl.statistics.getStatisticsData,
                param
            ).then((res) => {
                if (res.code == "0") {
                    switch (indicator) {
                        case 0:
                            if (res.data != null && res.data != "null") {
                                this._totalNum = res.data.totalNum;
                            } else {
                                this._totalNum = "empty";
                            }
                            this.loading_1_top = 0;
                            break;
                        case 7:
                            if (res.data != null && res.data != "null") {
                                this._totalSessionNum = res.data.totalNum;
                            } else {
                                this._totalSessionNum = "empty";
                            }
                            this.loading_1_bottom = 0;
                            break;
                        case 1:
                            if (res.data == null) {
                                this.messageNumberChartDataEmpty = true;
                                this.messageNumberChartData.rows = [];
                                this.loading_2 = 0;
                                return;
                            } else {
                                this.messageNumberChartDataEmpty = false;
                                this.messageNumberChartData.totalNum =
                                    res.data.totalNum;
                                this.messageNumberChartData.columns =
                                    res.data.columns;
                                this.messageNumberChartData.rows =
                                    res.data.data;
                            }
                            this.loading_2 = 0;
                            break;
                        case 2:
                            if (res.data == null) {
                                this.recognitionRateChartDataEmpty = true;
                                this.recognitionRateChartData.rows = [];
                                this.loading_3 = 0;
                                return;
                            } else {
                                this.recognitionRateChartDataEmpty = false;
                                let data = res.data.totalRate;
                                let result = (data * 100)
                                    .toString()
                                    .indexOf(".");
                                if (result != -1) {
                                    let totalRate = (data * 100).toFixed(2);
                                    this.recognitionRateChartData.totalRate =
                                        totalRate;
                                } else {
                                    let totalRate = (data * 100).toFixed(0);
                                    this.recognitionRateChartData.totalRate =
                                        totalRate;
                                }
                                this.recognitionRateChartData.columns =
                                    res.data.columns;
                                this.recognitionRateChartData.rows =
                                    res.data.data;
                            }
                            this.loading_3 = 0;
                            break;
                        case 4:
                            this._avResponseSec = res.data && res.data.respTime ? res.data.respTime : null;
                            this.loading_4_bottom = 0;
                            break;
                        case 3:
                            this._firstResponseSec = res.data && res.data.respTime ? res.data.respTime : null;
                            this.loading_4_top = 0;
                            break;
                        case "0,7":
                            if (!res.data || res.data.data == null) {
                                this.peopleNumberchartDataEmpty = true;
                                this.peoplelargechartData.rows = [];
                                this.peopleNumberchartData.rows = [];
                                this.peopleNumberchartData.columns = [];
                                this.peoplelargechartSettings.yAxisName = [];
                            } else {
                                this.peopleNumberchartDataEmpty = false;
                                this.peopleNumberchartData.columns =
                                    res.data.columns;
                                this.peopleNumberchartData.rows = res.data.data;
                                this.peoplelargechartData.rows = res.data.data;
                                this.peoplelargechartData.columns =
                                    res.data.columns;
                                this.peoplelargechartSettings.yAxisName = [
                                    "客户数",
                                    "",
                                ];
                            }
                            this.loading_m_1 = 0;
                            break;
                        case "1,2":
                            if (!res.data || res.data.data == null) {
                                this.messagelargechartDataEmpty = true;
                                this.messagelargechartData.columns = ["日期", "访问用户", "识别率"];
                                this.messagelargechartData.rows = [{ 日期: "", 访问用户: "", 识别率: "" }];
                                this.messagelargechartSettings.yAxisName = [];
                            } else {
                                this.messagelargechartDataEmpty = false;
                                this.messagelargechartData.columns =
                                    res.data.columns;
                                this.messagelargechartData.rows = res.data.data;
                                this.messagelargechartSettings.yAxisName = [
                                    "客户消息数",
                                    "识别率",
                                ];
                            }
                            this.loading_m_2 = 0;
                            break;
                        case 5:
                            if (res.data != null && res.data != "null") {
                                let tampSArr = [];
                                res.data.data.forEach((item) => {
                                    tampSArr.push({
                                        name: item.名称,
                                        value: item.数量,
                                    });
                                });
                                this.satisfTypeEcharts(tampSArr);
                                this.satisfTypeEchartsIsEmpty = false;
                            } else {
                                this.satisfTypeEchartsIsEmpty = true;
                            }
                            this.loading_5 = 0;
                            break;
                        case 6:
                            if (res.data != null && res.data != "null") {
                                let tampNSArr = [];
                                res.data.data.forEach((item) => {
                                    tampNSArr.push({
                                        name: item.名称,
                                        value: item.数量,
                                    });
                                });
                                this.noSatisfTypeEcharts(tampNSArr);
                                this.noSatisfTypeEchartsIsEmpty = false;
                            } else {
                                this.noSatisfTypeEchartsIsEmpty = true;
                            }
                            this.loading_6 = 0;
                            break;
                        case 10:
                            if (res.data != null && res.data != "null") {
                                this.intentTopTen = res.data.data;
                            } else {
                                this.intentTopTen = [];
                            }
                            this.loading_10_left = 0;
                            break;
                        case 9:
                            if (res.data != null && res.data != "null") {
                                let tampQwArr = [];
                                res.data.data.forEach((item) => {
                                    if(item.数量 > 0){
                                        tampQwArr.push({
                                            name: item.名称,
                                            value: item.数量,
                                        });
                                    }
                                });
                                this.qwTypeEcharts(tampQwArr);
                                if(tampQwArr.length === 0 ){
                                    this.qwTypeEchartsIsEmpty = true;
                                    this.loading_9 = 0;
                                } else {
                                    this.qwTypeEchartsIsEmpty = false;
                                }
                            } else {
                                this.qwTypeEchartsIsEmpty = true;
                            }
                            console.log( this.qwTypeEchartsIsEmpty,'000');
                            this.loading_9 = 0;
                            break;
                        case 11:
                            if (res.data != null && res.data != "null") {
                                let tampFeedbackArr = [];
                                res.data.data.forEach((item) => {
                                    tampFeedbackArr.push({
                                        name: item.名称,
                                        value: item.数量,
                                    });
                                });
                                this.feedbackEcharts(tampFeedbackArr);
                                this.feedbackEchartsIsEmpty = false;
                            } else {
                                this.feedbackEchartsIsEmpty = true;
                            }
                            this.loading_11 = 0;
                            break;
                        case 12:
                            if (res.data != null && res.data != "null") {
                                this.qwTopTen = res.data.data;
                            } else {
                                this.qwTopTen = [];
                            }
                            this.loading_10_right = 0;
                            break;
                        case 13:
                            if (res.data != null && res.data != "null") {
                                this.areaList = [...res.data.data];
                                this.$refs.areaComponent.setAreaList(
                                    this.areaList
                                );
                            } else {
                                this.areaList = [];
                            }
                            this.loading_13 = 0;
                            break;
                         case 18:
                            let list = [];
                            if (res.data && res.data.data != null && res.data.data != "null" && res.data.data.length > 0) {
                                res.data.data.forEach((item,index) => {
                                    list.push({
                                        '分类':item.typeName,
                                        '数量':item.hits
                                    })
                                })
                                this.qaConsultechartData.columns = ["分类","数量"]
                                this.qaConsultechartData.rows = [...list];
                                this.qaConsulterchartDataEmpty = false;
                            } else {
                                this.qaConsulterchartDataEmpty = true;
                                this.qaConsultechartData.rows = [];
                                this.qaConsultechartData.columns = ["分类","数量"];
                                this.qaConsultechartSettings.yAxisName = [];
                            }
                            this.loading_18 = 0;
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (indicator) {
                        case 0:
                            this.loading_1_top = 0;
                            this._totalNum = "empty";
                            break;
                        case 7:
                            this.loading_1_bottom = 0;
                            this._totalSessionNum = "empty";
                            break;
                        case 1:
                            this.loading_2 = 0;
                            this.messageNumberChartDataEmpty = true;
                            this.messageNumberChartData.rows = [];
                            break;
                        case 2:
                            this.loading_3 = 0;
                            this.messagelargechartDataEmpty = true;
                            this.messagelargechartData.columns = [];
                            this.messagelargechartData.rows = [];
                            this.messagelargechartSettings.yAxisName = [];
                            break;
                        case 3:
                            this.loading_4_top = 0;
                            break;
                        case 4:
                            this.loading_4_bottom = 0;
                            break;
                        case "0,7":
                            this.peopleNumberchartDataEmpty = true;
                            this.peoplelargechartData.rows = [];
                            this.peopleNumberchartData.rows = [];
                            this.peopleNumberchartData.columns = [];
                            this.peoplelargechartSettings.yAxisName = [];
                            this.loading_m_1 = 0;
                            break;
                        case "1,2":
                            this.messagelargechartDataEmpty = true;
                            this.messagelargechartData.columns = [];
                            this.messagelargechartData.rows = [];
                            this.messagelargechartSettings.yAxisName = [];
                            this.loading_m_2 = 0;
                            break;
                        case 5:
                            this.loading_5 = 0;
                            this.satisfTypeEchartsIsEmpty = true;
                            break;
                        case 6:
                            this.loading_6 = 0;
                            this.noSatisfTypeEchartsIsEmpty = true;
                            break;
                        case 10:
                            this.loading_10_left = 0;
                            this.intentTopTen = [];
                            break;
                        case 9:
                            this.loading_9 = 0;
                            this.qwTypeEchartsIsEmpty = true;
                            break;
                        case 11:
                            this.loading_11 = 0;
                            this.feedbackEchartsIsEmpty = true;
                            break;
                        case 12:
                            this.loading_10_right = 0;
                            this.qwTopTen = [];
                            break;
                        case 13:
                            this.loading_13 = 0;
                            this.areaList = [];
                            break;
                        default:
                            break;
                    }
                }
            });
        },
        reload13() {
            this.getStatisticsDataNew(
                this.newStartTime,
                this.newEndTime,
                13,
                "indicator"
            );
        },
        // 全部加载数据
        getDataCell(startTime, endTime) {
            this.getStatisticsDataNew(startTime, endTime, 3, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 4, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 7, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 0, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 9, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 18, "indicator");
            setTimeout(() => {
                this.getStatisticsDataNew(startTime, endTime, 1, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 2, "indicator");
                this.getStatisticsDataNew(
                    startTime,
                    endTime,
                    "0,7",
                    "indicators"
                );
                this.getStatisticsDataNew(
                    startTime,
                    endTime,
                    "1,2",
                    "indicators"
                );
            }, 300);
            setTimeout(() => {
                this.getStatisticsDataNew(startTime, endTime, 5, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 6, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 10, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 12, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 11, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 13, "indicator");
            }, 800);
        },
        // 保存设置问题类型
        saveSetType(list) {
            this.FetchPost(
                this.requestUrl.statistics.configQuestionType +
                    this.formatterActiveScopeValue(this.activeScopeValue) +
                    "?scopeType=" +
                    this.activeScopeType,
                {
                    questionTypeConfigs: list,
                }
            ).then((res) => {
                this.getStatisticsDataNew(
                    this.newStartTime,
                    this.newEndTime,
                    9,
                    "indicator"
                );
                this.setTypeDialogVisible = false;
            });
        },
        // 取消保存设置问题类型
        cancelSaveSetType() {
            this.setTypeDialogVisible = false;
        },
        todayData() {
            this.currentDate = "today";
            let endTime = new Date().getTime();
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let todayStartTime = yy + "-" + mm + "-" + dd + " " + "0:0:0";
            let todayStartTimeDate = new Date(
                Date.parse(todayStartTime.replace(/-/g, "/"))
            );
            let startTime = todayStartTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        yesterdayData() {
            this.currentDate = "yesterday";
            var time = new Date().getTime() - 24 * 60 * 60 * 1000;
            var yesday = new Date(time); // 获取的是前一天日期
            yesday =
                yesday.getFullYear() +
                "-" +
                (yesday.getMonth() > 9
                    ? yesday.getMonth() + 1
                    : yesday.getMonth() + 1) +
                "-" +
                yesday.getDate();

            let yesterdayStartTime = yesday + " " + "0:0:0";
            let yesterdayEndTime = yesday + " " + "23:59:59";
            let yesterdayStartTimeDate = new Date(
                Date.parse(yesterdayStartTime.replace(/-/g, "/"))
            );
            let yesterdayEndTimeDate = new Date(
                Date.parse(yesterdayEndTime.replace(/-/g, "/"))
            );
            let startTime = yesterdayStartTimeDate.getTime();
            let endTime = yesterdayEndTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        lastWeekData() {
            this.currentDate = "lastWeek";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-6);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        lastMonthData() {
            this.currentDate = "lastMonth";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-29);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        datePickerChange() {
            if(this.fastChooseDatePickerType === 'monthrange'){
                if(this.$refs.fastChooseDatePicker){
                    this.$refs.fastChooseDatePicker.handlerMonthClose();
                }
            } 
            if (this.dateSeleteData.value) {
                this.currentDate = "diy";
                // let d = this.dateSeleteData.value[0];
                // let startTimeDated =
                //     d.getFullYear() +
                //     "-" +
                //     (d.getMonth() + 1) +
                //     "-" +
                //     d.getDate() +
                //     " " +
                //     d.getHours() +
                //     ":" +
                //     d.getMinutes() +
                //     ":" +
                //     d.getSeconds();
                // let startTimeDate = new Date(
                //     Date.parse(startTimeDated.replace(/-/g, "/"))
                // );
                // let startTime = startTimeDate.getTime();
                // let end = this.dateSeleteData.value[1];
                // let e = new Date(end);
                // let endday =
                //     e.getFullYear() +
                //     "-" +
                //     (e.getMonth() + 1) +
                //     "-" +
                //     e.getDate();
                // let timestamp = new Date().getTime();
                // let yy = new Date().getFullYear();
                // let mm = new Date().getMonth() + 1;
                // let dd = new Date().getDate();
                // let today = yy + "-" + mm + "-" + dd;
                // let todaystamp = Date.parse(today);
                // let enddaystamp = Date.parse(endday);
                // let endTime = "";
                // if (todaystamp === enddaystamp) {
                //     endTime = timestamp;
                // } else {
                    // let endTimeDated =
                    //     e.getFullYear() +
                    //     "-" +
                    //     (e.getMonth() + 1) +
                    //     "-" +
                    //     e.getDate() +
                    //     " " +
                    //     "23:59:59";
                    // let endTimeDate = new Date(
                    //     Date.parse(endTimeDated.replace(/-/g, "/"))
                    // );
                    // endTime = endTimeDate.getTime();
                // }
                console.log(this.dateSeleteData.value,22222222);
            if(this.dateSeleteData.value !== '' && this.dateSeleteData.value.length === 2){
                if(typeof(this.dateSeleteData.value[0]) !== 'number'){
                    this.newStartTime = this.dateSeleteData.value[0].getTime();
                } 
                if(typeof(this.dateSeleteData.value[1])!== 'number'){
                    this.newEndTime = this.dateSeleteData.value[1].getTime();
                } 
                // this.newStartTime = this.dateSeleteData.value[0].getTime();
                // this.newEndTime = this.dateSeleteData.value[1].getTime();
            }
            // if(end > new Date().getTime()){
            //     this.newEndTime = new Date().getTime();
            //     this.dateSeleteData.value[1] = new Date().getTime();
            // }
            // if(start > new Date().getTime()){
            //     this.newStartTime = new Date().getTime();
            //     this.dateSeleteData.value[0] = new Date().getTime();
            // }
            this.getDataCell(this.newStartTime,  this.newEndTime);

                // if (startTime > timestamp || endTime > timestamp) {
                //     this.dateSeleteData = [];
                //     this.$message.error("不能穿越哦，请重新选择：");
                // } else {
                //     this.newStartTime = startTime;
                //     this.newEndTime = endTime;
                //     this.getDataCell(startTime, endTime);
                // }
            } else {
                console.log(1111);
                this.yesterdayData();
            }
        },
        changeGetData() {
            console.log(this.currentDate,11);
            switch (true) {
                case this.currentDate === "today":
                    this.todayData();
                    break;
                case this.currentDate === "yesterday":
                    this.yesterdayData();
                    break;
                case this.currentDate === "lastWeek":
                    this.lastWeekData();
                    break;
                case this.currentDate === "lastMonth":
                    this.lastMonthData();
                    break;
                case this.currentDate === "diy":
                    this.datePickerChange();
                    break;
            }
        },
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        init() {
            const self = this; //因为箭头函数会改变this指向，指向windows。所以先把this保存
            // setTimeout(() => {
            //     window.onresize = function () {
            //         self.chart = echarts.init(self.$refs.myEchart);
            //         self.chart.resize();
            //     };
            // }, 20);
        },
    },
    mounted() {
        // // 获取当前周的第一天
        // const start = new Date(
        //     end.getFullYear(),
        //     end.getMonth(),
        //     end.getDate() - ((end.getDay() + 6) % 7)
        // ); 
        // const startTime = new Date(start.setHours(0, 0, 0, 0))
        // start.setTime(startTime.getTime());

        let date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        let startTime = new Date(date.setHours(0, 0, 0, 0))
        let start = startTime.setTime(startTime.getTime());

        let endTime = new Date(date.setHours(23, 59, 59, 999))
        let end = endTime.getTime();

        this.newStartTime = start;
        this.newEndTime = end;
        this.dateSeleteData.value = [ start,end]
        this.getStatisticsScopes();
        this.init();
        this.$nextTick(() => {
            this.showMap = true;
        });
    },
    watch:{
        '$parent.testDrawerVisibleP'(n){
            console.log(n,7777777);
            this.histogramNum++;
            // this.getStatisticsDataNew(this.newStartTime,this.newEndTime, "0,7","indicators" );
            // this.getStatisticsDataNew(this.newStartTime,this.newEndTime,"1,2","indicators" );
            // this.getStatisticsDataNew(this.newStartTime,this.newEndTime, 18, "indicator");
        }
    }
};
</script>
<style lang="less" scoped>
#statistics {
    overflow-x: auto;
    overflow-y: hidden;
    .top-header-right {
        justify-content: flex-start;
    }
    .content-main{
        min-width: 1140px;
    }
}
@import "./../../assets/less/statistics/statistics.less";
</style>