<template>
    <div id="top-ten">
        <el-table
            size="mini"
            cell-style="{text-align: center;}"
            v-loading="loading"
            v-if="tableType == 'intent'"
            :data="tableData"
        >
            <el-table-column prop="index" label="排名" width="50">
            </el-table-column>
            <el-table-column prop="intentName" label="意图名称">
            </el-table-column>
            <el-table-column prop="hits" width="80" label="咨询频次">
            </el-table-column>
        </el-table>
        <el-table
            size="mini"
            v-loading="loading"
            v-if="tableType == 'qw'"
            :data="tableData"
        >
            <el-table-column prop="index" label="排名" width="50">
            </el-table-column>
            <el-table-column prop="question" label="问题">
            </el-table-column>
            <el-table-column prop="hits" width="80" label="咨询频次">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name: "top-ten",
    props: ["tableData", "tableType", "loading"],
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>
<style lang="less" scoped>
#top-ten {
}
</style>